import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ProductList from './components/components/products/productList';
import ProductDetail from './components/components/products/productDetail';
import Navbar from './navbar';
import Home from './pages/Home';
import Cart from './components/components/cart/cart';
// import NotFound from './pages/';
import AdminPage from './components/components/AdminPage';
import Login from './components/components/auth/Login';
import Register from './components/components/auth/Register';
import PrivateRoute from './components/components/PrivateRoute';
import { AuthProvider } from './components/context/authContext';
import { CartProvider } from './components/context/cartContext';
// import ProtectedComponent from "./components/components/ProtectedComponent";
import ShippingMethod from './components/components/orders/ShippingMethod';
import OrderSumary from './components/components/orders/OrderSumary';
import OrderPlaced from './components/components/orders/OrderedPlaced';
import UserProfile from './components/components/UserProfile.jsx';
import InventoryReport from './components/components/InventoryReport.jsx';
import EditProductsPage from './EditProductsPage'
import SalePolicies from './pages/Politicas.jsx';
import OrderHistory from './pages/OrdersHistorybyUser.jsx';  // Importar la nueva página
import ResetPassword from './components/components/auth/ResetPassword.jsx';
import RequestPasswordReset from './components/components/auth/RequestPasswordReset.jsx';
// import ForgotPasswordIdentifier from './components/components/auth/ForgotPasswordIdentifier.jsx'
import SecurityQuestion from './components/components/auth/SecurityQuestion.jsx'
// import TimerDisplay from './components/components/cart/TimerDisplay.jsx'; // Importa el componente TimerDisplay
import PuntoDeVenta from './pages/POS.jsx'
import OrderSearchPOS from './pages/POSSystem.jsx'

const App = () => {
  const [orderDetails, setOrderDetails] = useState({});
  const location = useLocation(); // Obtener la ruta actual

  const showNavbar = location.pathname !== '/login' && location.pathname !== '/punto-de-venta-busqueda';

  const handleNext = (details) => {
    setOrderDetails(details);
    console.log("Order details:", details);
  };
  
  return (
<AuthProvider>
  <CartProvider>
    <div>
      {showNavbar && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<ProductList />} />
        <Route path="/product/:id" element={<ProductDetail />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
       {/*   <Route path="*" element={<NotFound />} />
       <Route path="/punto-de-venta" element={<PuntoDeVenta />} /> */}
        <Route path="/punto-de-venta-busqueda" element={<OrderSearchPOS />} />

        {/* Ruta para el administrador (admin) */}
          <Route element={<PrivateRoute roles={['admin']} />}>
            <Route path="/admin/edit-products" element={<EditProductsPage />} />
          </Route>

          {/* Ruta para el administrador (admin) y editor (editor) */}
          <Route element={<PrivateRoute roles={['admin', 'editor']} />}>
            <Route path="/admin" element={<AdminPage />} />
            {/* <Route path="/order-history" element={<OrderHistory />} /> */}
            <Route path="/punto-de-venta" element={<OrderSearchPOS />} />
          </Route>

        {/* Ruta para el perfil de cliente o administrador */}
        <Route element={<PrivateRoute roles={['client', 'admin', 'editor']} />}>
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/order-history" element={<OrderHistory />} />

        </Route>

        {/* Ruta para historial de pedidos que permite acceso a admin, client y editor */}
        {/* <Route element={<PrivateRoute roles={['admin', 'client', 'editor']} />}>
          <Route path="/order-history" element={<OrderHistory />} />  {/* Actualizar roles permitidos */}
        {/* </Route> */}

        <Route path="/report" element={<InventoryReport/>}></Route>
        <Route path="/shipping-method" element={<ShippingMethod onNext={handleNext} />} />
        <Route path="/order-summary" element={<OrderSumary details={orderDetails} />} />
        <Route path="/order-placed" element={<OrderPlaced />} />
        <Route path="/policies" element={<SalePolicies />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/request-password" element={<RequestPasswordReset />} />
        {/* <Route path="/forgot-password/identifier" element={<ForgotPasswordIdentifier />} /> */}
        <Route path="/forgot-password/security-question" element={<SecurityQuestion />} />
      </Routes>
    </div>
  </CartProvider>
</AuthProvider>
  );
};


export default App;